@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;600;700&display=swap');

:root {
	--bs-blue: #206bc4;
	--bs-azure: #45aaf2;
	--bs-indigo: #6574cd;
	--bs-purple: #a55eea;
	--bs-pink: #f66d9b;
	--bs-red: #cd201f;
	--bs-orange: #ff922b;
	--bs-yellow: #fab005;
	--bs-lime: #94d82d;
	--bs-green: #5eba00;
	--bs-teal: #2bcbba;
	--bs-cyan: #17a2b8;
	--bs-gray: #a8aeb7;
	--bs-gray-dark: #545d6d;
	--bs-dark: #354052;
	--bs-primary: #206bc4;
	--bs-secondary: #6e7582;
	--bs-secondary-3: #c4c7cf;
	--bs-success: #5eba00;
	--bs-info: #45aaf2;
	--bs-warning: #fab005;
	--bs-danger: #cd201f;
	--bs-light: #f5f7fb;
	--bs-dark: #354052;
	--bs-font-sans-serif: "Ubuntu", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
	--bs-font-monospace: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace
}

*, ::after, ::before {
	box-sizing: border-box
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: var(--bs-font-sans-serif);
	font-size: .875rem;
	font-weight: 400;
	line-height: 1.7142857;
	color: #354052;
	background-color: #ebebeb;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	height: 10vh;
	overscroll-behavior: contain;
}

.app-tab-section [role="tabpanel"] .MuiBox-root {
	padding: unset;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root {
    box-shadow: unset !important;
}
.MuiButton-containedPrimary {
	background: #29a8e0 !important
}
.css-1wc848c-MuiFormHelperText-root {
	margin-left: 0 !important;
}